import React, { useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
// import ReactDOM from 'react-dom'



function Create() {
    const navigate = useNavigate();
    const cardColors = ["#ffdb58", "#50c878", "#468fea", "#b272e0"];
    const [clues, setClues] = useState(["", "", "", ""]);
    const [categories, setCategories] = useState(["", "", "", ""]);
    const [errors, setErrors] = useState([false, false, false, false]);

    const [gameName, setGameName] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const [fieldErrors, setFieldErrors] = useState({
        gameName: false,
        createdBy: false,
        categories: [false, false, false, false],
    });

    const [alert, setAlert] = useState({ type: "", message: "", show: false });
    const [generatedLink, setGeneratedLink] = useState(null);


    const handleGameNameChange = (e) => {
        setGameName(e.target.value);
        setFieldErrors((prev) => ({ ...prev, gameName: false })); // Clear error on change
    };

    const handleCreatedByChange = (e) => {
        setCreatedBy(e.target.value);
        setFieldErrors((prev) => ({ ...prev, createdBy: false })); // Clear error on change
    };

    const handleCluesChange = (index, value) => {
        const updatedClues = [...clues];
        updatedClues[index] = value;
        setClues(updatedClues);

        // Split clues by commas and trim spaces
        const clueWords = value.split(",").map((clue) => clue.trim());

        // Validate that there are exactly 4 clues and no duplicates within the group
        const commaCount = (value.match(/,/g) || []).length;
        let updatedErrors = [...errors];

        // Check for duplicates in this group and globally
        if (
            commaCount !== 3 ||
            clueWords.length !== 4 ||
            new Set(clueWords).size !== clueWords.length // Check for duplicates within the group
        ) {
            updatedErrors[index] = true; // Set error if there are issues with clues
        } else {
            updatedErrors[index] = false; // No error if clues are valid
        }

        setErrors(updatedErrors);
    };

    const handleCategoryChange = (index, value) => {
        const updatedCategories = [...categories];
        updatedCategories[index] = value;
        setCategories(updatedCategories);

        // Clear errors on valid input
        const updatedFieldErrors = { ...fieldErrors };
        updatedFieldErrors.categories[index] = false;
        setFieldErrors(updatedFieldErrors);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let hasErrors = false;
        const newFieldErrors = {
            gameName: false,
            createdBy: false,
            categories: [false, false, false, false],
        };

        // Validate form fields
        if (!gameName.trim()) {
            newFieldErrors.gameName = true;
            hasErrors = true;
        }

        if (!createdBy.trim()) {
            newFieldErrors.createdBy = true;
            hasErrors = true;
        }

        categories.forEach((category, index) => {
            if (!category.trim()) {
                newFieldErrors.categories[index] = true;
                hasErrors = true;
            }
        });

        if (errors.some((error) => error)) {
            hasErrors = true;
        }

        setFieldErrors(newFieldErrors);

        if (hasErrors) {
            setAlert({ type: "danger", message: "Form contains errors. Please fix them.", show: true });
            setTimeout(() => setAlert({ type: "", message: "", show: false }), 3000);
            return;
        }

        // Prepare game data for submission
        const gameData = {
            date: new Date().toISOString().split("T")[0],
            gameName: gameName,
            createdBy: createdBy,
            groups: categories.map((category, index) => ({
                category: category,
                clues: clues[index].split(",").map((clue) => clue.trim()),
            })),
        };

        try {
            const response = await fetch("https://www.connectionunlimited.net/gamedata/groupuser.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(gameData),
            });

            const result = await response.json();

            if (result.status === "success") {
                setAlert({ type: "success", message: "Game created successfully!", show: true });

                // Set the generated link
                const generatedFilePath = result.data.filename;
                const generatedUrl = `https://www.connectionunlimited.net/gamedata/jsondataUser/${generatedFilePath}`;

                setGeneratedLink(generatedUrl);

                // Redirect user to the own game page with the generated link
                navigate(`/own-game?file=${generatedFilePath}`);

                setTimeout(() => setAlert({ type: "", message: "", show: false }), 3000);
            } else {
                setAlert({ type: "danger", message: result.message, show: true });
                setTimeout(() => setAlert({ type: "", message: "", show: false }), 3000);
            }
        } catch (error) {
            setAlert({ type: "danger", message: "An error occurred. Please try again.", show: true });
            setTimeout(() => setAlert({ type: "", message: "", show: false }), 3000);
        }
    };





    // Flatten all clues into a single array for the grid
    const allClues = clues.flatMap((clue) => clue.split(",").map((clueItem) => clueItem.trim()));
    
    return (
        <>
            <div className="container my-4">
            <Helmet>
              <title>Create Connections Unlimited NYT Game | Play Latest & Classic Puzzles</title>
              <meta name="description"
                content="Create Connections Unlimited NYT Game Offers New and Past Puzzles. Enjoy Daily Challenges, Explore Archived Games, and improve your Word Skills Effortlessly." />
              <link rel="canonical" href="https://www.connectionunlimited.net/create" />
            </Helmet>
                <div className="row">
                    {alert.show && (
                        <div className={`alert alert-${alert.type}`} role="alert">
                            {alert.message}
                            {alert.link && (
                                <div>
                                    <a href={alert.link} target="_blank" rel="noopener noreferrer">
                                        {alert.link}
                                    </a>
                                </div>
                            )}
                        </div>
                    )}

                    <div className="col-md-5">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="mb-3">
                                <div className="mb-4">
                                    <div className="row mb-3">
                                        <div className="col">
                                            <div className="form-group">
                                                <input
                                                    name="gameName"
                                                    placeholder="Game name..."
                                                    autoComplete="off"
                                                    id="field-name"
                                                    className={`form-control ${fieldErrors.gameName ? "is-invalid" : ""
                                                        }`}
                                                    value={gameName}
                                                    onChange={handleGameNameChange}
                                                />
                                                {fieldErrors.gameName && (
                                                    <div className="invalid-feedback">
                                                        Game name is required.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col">
                                            <div className="form-group">
                                                <input
                                                    name="createdBy"
                                                    placeholder="Created by..."
                                                    autoComplete="off"
                                                    id="field-createdBy"
                                                    className={`form-control ${fieldErrors.createdBy ? "is-invalid" : ""
                                                        }`}
                                                    value={createdBy}
                                                    onChange={handleCreatedByChange}
                                                />
                                                {fieldErrors.createdBy && (
                                                    <div className="invalid-feedback">
                                                        Created by is required.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Loop through 4 clue fields */}
                                {[0, 1, 2, 3].map((index) => (
                                
                                    <div
                                        className="mb-4 card p-3 shadow-sm"
                                        key={`category-${index}`}
                                        style={{ backgroundColor: cardColors[index] }}
                                    >
                                        <div className="mb-3">
                                           <div className="d-flex justify-content-between">
                                             <label
                                                htmlFor={`field-category-${index}`}
                                                className="form-label"
                                            >
                                                Category
                                            </label>
                                            <svg width={20} height={15} viewBox="0 0 10 10" focusable="false" className="chakra-icon css-onkibi"><path fill="currentColor" d="M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z"></path>
                                        </svg>
                                           </div>
                                            <input
                                                name={`category${index}`}
                                                placeholder="Enter category..."
                                                id={`field-category-${index}`}
                                                className={`form-control ${fieldErrors.categories[index] ? "is-invalid" : ""
                                                    }`}
                                                value={categories[index]}
                                                onChange={(e) =>
                                                    handleCategoryChange(index, e.target.value)
                                                }
                                            />
                                            {fieldErrors.categories[index] && (
                                                <div className="invalid-feedback">
                                                    Category is required.
                                                </div>
                                            )}
                                        </div>
                                        
                                        <div className="mb-3">
                                            <label
                                                htmlFor={`field-clues-${index}`}
                                                className="form-label"
                                            >
                                                Clues (comma separated)
                                            </label>
                                            <input
                                                name={`clues${index}`}
                                                placeholder="Enter clues..."
                                                id={`field-clues-${index}`}
                                                className={`form-control ${errors[index] ? "is-invalid" : ""
                                                    }`}
                                                value={clues[index]}
                                                onChange={(e) =>
                                                    handleCluesChange(index, e.target.value)
                                                }
                                            />
                                            {errors[index] && (
                                                <div className="invalid-feedback">
                                                    Please enter exactly four clues separated by commas, with no duplicates.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                  
                                ))}

                            </div>
                        </form>
                    </div>
                    <div className="col-md-7">
                        <div className="heading-create-game text-center">
                            <h4 className="mb-0 pb-0">{gameName || "Game name..."}</h4>
                            <h5>{createdBy || "Created by me!"}</h5>
                            <p>Create four groups of four!</p>
                        </div>

                        {/* <div id="grid">
                            {allClues.length > 0 ? (
                                allClues.map((clue, index) => (
                                    <div className="word" key={index}>
                                        {clue}
                                    </div>
                                ))
                            ) : (
                                <p>No clues available</p>
                            )}
                        </div> */}
                        <div id="grid">
                            {useMemo(() => {
                                // Shuffle the clues array and ensure it's always 16 elements
                                const shuffledClues = [...allClues].sort(() => Math.random() - 0.5);
                                const finalClues = Array.from({ length: 16 }, (_, index) => shuffledClues[index] || "");

                                // Render the clues
                                return finalClues.map((clue, index) => (
                                    <div className="word" key={index}>
                                        {clue}
                                    </div>
                                ));
                            }, [allClues])}
                        </div>


                        <div className="text-center">
                            <button type="submit" className="btn btn-dark rounded-pill btn-lg" onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>

                        <div className="bg-light">
                            {generatedLink && (
                                <p>
                                    Generated Link: <a href={generatedLink} target="_blank" rel="noopener noreferrer">{generatedLink}</a>
                                </p>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Create;
