import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs'; // Import your About Us page
import Game from './components/Game';
import PrivacyPolicy from './components/PrivacyPolicy';
import ContactUs from './components/ContactUs';
import BlogDetail from './components/BlogDetail';
import Blog from './Blog';
import Archive from './components/Archive';
import Create from './components/Create';
import GameCreate from './components/GameCreate';
// import SocialShare from './components/SocialShare';
import GameDifficulty from './components/GameDifficulty';

const App = () => {
  return (
    <Router>
      <>
        {/* Navigation Links */}
        <div className="bg-light border-bottom">
          <nav className="container d-flex align-items-center justify-content-between py-2">
            {/* Logo */}
            <div className="d-flex align-items-center gap-2"> {/* Flex container for image and h1 */}
              <a href="/" className="d-flex align-items-center text-decoration-none text-dark">
                <img className="my-2" src="/fav.png" width={40} height={40} alt="logo" />
                <h3 className='logo-text mb-0 ms-2 d-none d-md-block'>Connections Unlimited</h3> {/* Adding margin-left to the heading */}
              </a>
            </div>

            {/* Navigation Links */}
            <div className="d-flex gap-3">
              <a href="/connections-archive" className="nav-menu-color">Archive</a>
              <a href="/#help" className="nav-menu-color">Help</a>
              <a href="/create" className="create">Create</a>
            </div>
          </nav>
        </div>


        {/* Define Routes */}
        <Routes>

          <Route path="/" element={<Game />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogDetail />} />
          <Route path='/connections-archive' element={<Archive />} />
          <Route path="/game" element={<Game />} />
          <Route path="/create" element={<Create />} />
          <Route path="/own-game" element={<GameCreate />} />
          <Route path="/game-difficulty" element={<GameDifficulty />} />

        </Routes>
        
        {/* <SocialShare  currentURL={currentURL}/> */}
        <div className="sharethis-sticky-share-buttons"></div>
        <Footer />
      </>
    </Router>
  );
};

export default App;
