import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import StylishSpinner from './StylishSpinner';
import Time from './Timer';
import { useNavigate } from 'react-router-dom';
import ConfettiComponent from './ConfettiComponent';
import { Helmet } from 'react-helmet';


const Game = () => {
    const [data, setData] = useState([]); // Shuffled members
    const [groups, setGroups] = useState([]); // Original groups
    const [loading, setLoading] = useState(true);
    const [selectedItems, setSelectedItems] = useState([]);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [gameComplete, setGameComplete] = useState(false);
    const [mistakes, setMistakes] = useState(0);
    const [shake, setShake] = useState(false);
    const [completedGroups, setCompletedGroups] = useState([]); // Store completed groups
    const groupColors = ["#ffdb58", "#50c878", "#468fea", "#b272e0"];
    const [gameName, setGameName] = useState(''); // Store gameName
    const [createdBy, setCreatedBy] = useState(''); // Store createdBy

    // Get file parameter from URL
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const filename = params.get("file");
    const file = params.get("file");

    const [activeDifficulty, setActiveDifficulty] = useState('');
    useEffect(() => {
        if (file) {
            const difficulty = file.split('-').pop().replace('.json', '').toLowerCase();
            setActiveDifficulty(difficulty); // Set the extracted difficulty as active
        }
    }, [file]);
    
    // Fetch data for the selected file
    useEffect(() => {
        const fetchData = async () => {

            try {
                setLoading(true);
                const response = await axios.get(`https://www.connectionunlimited.net/gamedata/difficultyapi.php?file=${file}`);
                const fileData = response.data.find(file => file.filename === filename)?.data.groups || [];
                const groupData = fileData;
                setGroups(groupData); // Store original groups structure

                const shuffledData = shuffleArray(groupData.flatMap(group => group.members));
                setData(shuffledData); // Store shuffled members
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [file]);

    // Shuffle array function
    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    // Handle item click
    const handleItemClick = (item) => {
        setSelectedItems(prevItems => {
            const isAlreadySelected = prevItems.includes(item);
            if (isAlreadySelected) {
                return prevItems.filter(selected => selected !== item);
            } else {
                if (prevItems.length < 4) {
                    return [...prevItems, item];
                } else {
                    setMessage('You can only select up to 4 items.');
                    setMessageType('info');
                    return prevItems;
                }
            }
        });
    };

    // Handle form submission
    const handleSubmit = () => {
        if (selectedItems.length !== 4) {
            setMessage('You need to select 4 items before submitting.');
            setMessageType('info');
            return;
        }

        // Check if selected items match any group
        const matchedGroup = groups.find(group => {
            const groupSet = new Set(group.members); // Convert group members to a set
            const selectedSet = new Set(selectedItems); // Convert selected items to a set

            return groupSet.size === selectedSet.size &&
                [...groupSet].every(clue => selectedSet.has(clue)); // Check if sets are equal
        });

        if (matchedGroup) {
            const matchedIndex = groups.indexOf(matchedGroup); // Find the index of the matched group
            setMessage('Success! You correctly identified the group.');
            setMessageType('success');
            setGameComplete(true);

            // setCompletedGroups(prevGroups => [...prevGroups, { groupName: matchedGroup.category, members: matchedGroup.members }]);
            setCompletedGroups(prevGroups => [...prevGroups, {
                groupName: matchedGroup.group,
                members: matchedGroup.members,
                index: matchedIndex
            }]);

            // Remove matched group members from the data pool
            setData(prevData => prevData.filter(item => !matchedGroup.members.includes(item)));
            setSelectedItems([]); // Clear selected items
        } else {
            setMistakes(prevMistakes => {
                if (prevMistakes < 3) {
                    setShake(true); // Trigger shake animation
                    setTimeout(() => setShake(false), 500); // Reset shake after animation
                    setMessage(`Incorrect guess, try again. Mistakes remaining: ${3 - prevMistakes}`);
                    setMessageType('warning');
                } else {
                    setMessage('Game over! You have made 4 mistakes.');
                    setMessageType('danger');
                    setGameComplete(true);
                }
                return prevMistakes + 1;
            });
        }
    };

    const handleDeselect = () => {
        setSelectedItems([]);
    };

    // Updated getGroupClass function
    const getGroupClass = (index) => {
        const color = groupColors[index] || "light"; // Default to light if index exceeds groupColors length
        return { backgroundColor: color, padding: "10px", borderRadius: "5px" };
    };

    // Function to shuffle members
    const handleShuffle = () => {
        setData(prevData => shuffleArray([...prevData])); // Shuffle the current data
        setMessage('The members have been shuffled!');
        setMessageType('info');
    };

    const [showModal, setShowModal] = useState(false); // Modal visibility
    const [currentURL, setCurrentURL] = useState('');
    const [copyMessage, setCopyMessage] = useState(''); // Message for copy action

    useEffect(() => {
        // Get the current page URL and set it
        setCurrentURL(window.location.href);
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(currentURL); // Copy URL to clipboard
        setCopyMessage('Link copied to clipboard!');
        setTimeout(() => setCopyMessage(''), 2000); // Clear message after 2 seconds
    };

    const [date, difficulty] = React.useMemo(() => {
        if (!filename) return ["Date not available", "Difficulty not available"];
        const parts = filename.replace(".json", "").split("-");
        const date = parts.slice(0, 3).join("-"); // First three parts as the date
        const difficulty = parts[3] ? parts[3].toUpperCase() : "Unknown"; // Fourth part as difficulty
        return [date, difficulty];
    }, [filename]);

    const navigate = useNavigate();
    // const handleButtonClick = (difficulty) => {
    //     const fileWithoutExtension = date; // Remove .json from filename
    //     // Navigate to the new page with difficulty and date (file name without .json)
    //     // navigate(`/game-difficulty?option=${difficulty.toLowerCase()}&date=${fileWithoutExtension}`);
    //     navigate(`/game-difficulty?file=${fileWithoutExtension}-${difficulty.toLowerCase()}.json`);
    // };
    const handleButtonClick = (difficulty) => {
        const date = file.split('-').slice(0, 3).join('-'); // Extract date part of the file
        const newFileName = `${date}-${difficulty.toLowerCase()}.json`;
        navigate(`/game-difficulty?file=${newFileName}`);
    };
    const copyToClipboard = () => {
        const textToCopy = "connectionunlimited.net - 🟡🟡🟡🟡🔵🔵🔵🔵🔴🔴🔴🔴🟢🟢🟢🟢";
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                alert("Copied to Clipboard, you can now share the result in social media.");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    };

    return (
        <>
            {/* Modal */}
            

            <div className="game-container">
            <Helmet>
              <title>Game Difficulty Connections Unlimited NYT Game | Play Latest & Classic Puzzles</title>
              <meta name="description"
                content="Game Difficulty Connections Unlimited NYT Game Offers New and Past Puzzles. Enjoy Daily Challenges, Explore Archived Games, and improve your Word Skills Effortlessly." />
              <link rel="canonical" href="https://www.connectionunlimited.net/game-difficulty" />
            </Helmet>
                <div className='row p-0'>
                <h5 className="text-center mt-3 mb-0 fw-bold">{difficulty}</h5>
                <p className="text-center mb-0">{date}</p>
                    <Time />
                    {completedGroups.length > 0 && (
                        <div className="mt-4 group-success">
                            {completedGroups.map((group, index) => (
                                <div
                                    key={index}
                                    className="game-group"
                                    style={getGroupClass(group.index)}
                                >
                                    <h3 className="group-name">{group.groupName}</h3>
                                    <div className="group-members">
                                        {group.members.join(', ')}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                
                {completedGroups.length < 4 ? (
                    <>
                {loading ? (
                    <StylishSpinner />
                ) : (
                    <div id="grid" className="">
                        {data.map((member, index) => {
                            const charCount = member.length; // Count the number of characters
                            const charClass = `letter-${charCount}`;
                            return (
                                <div
                                    key={index}
                                    onClick={() => handleItemClick(member)}
                                    className={`word ${charClass} ${selectedItems.includes(member) ? 'selected' : ''}
                                ${shake && selectedItems.includes(member) ? 'shake' : ''}`}
                                >
                                    {member}
                                </div>
                            );
                        })}
                    </div>
                )}

                <div className="mistakes" id="mistakes">
                    Mistakes remaining:
                    {Array(4) // Total 4 dots for mistakes
                        .fill()
                        .map((_, index) => (
                            <span
                                key={index}
                                className={`mx-2 mistake-dot ${index < mistakes ? 'used' : ''}`}
                            ></span>
                        ))}
                </div>
                <div className="text-center mt-4">

                    <Button onClick={handleShuffle} className="shuffle mx-1">Shuffle</Button>
                    <Button onClick={handleDeselect} className="deselectAll mx-1">Deselect</Button>
                    <Button onClick={handleSubmit} className="checkConnections mx-1">Submit</Button>
                </div>
                </>
                ) : (

                <>
                <div className="congratulations">
                        {/* <h2>Congratulations!</h2> */}
                        {/* <p>You have found all groups!</p> */}
                        <ConfettiComponent />
                        <div className="share">
                            <div className="share-row">
                                <span style={{ backgroundColor: "rgb(114, 158, 235)" }}></span>
                                <span style={{ backgroundColor: "rgb(114, 158, 235)" }}></span>
                                <span style={{ backgroundColor: "rgb(114, 158, 235)" }}></span>
                                <span style={{ backgroundColor: "rgb(114, 158, 235)" }}></span>
                            </div>
                            <div className="share-row">
                                <span style={{ backgroundColor: "rgb(251, 212, 0)" }}></span>
                                <span style={{ backgroundColor: "rgb(251, 212, 0)" }}></span>
                                <span style={{ backgroundColor: "rgb(251, 212, 0)" }}></span>
                                <span style={{ backgroundColor: "rgb(251, 212, 0)" }}></span>
                            </div>
                            <div className="share-row">
                                <span style={{ backgroundColor: "rgb(188, 112, 196)" }}></span>
                                <span style={{ backgroundColor: "rgb(188, 112, 196)" }}></span>
                                <span style={{ backgroundColor: "rgb(188, 112, 196)" }}></span>
                                <span style={{ backgroundColor: "rgb(188, 112, 196)" }}></span>
                            </div>
                            <div className="share-row">
                                <span style={{ backgroundColor: "rgb(181, 227, 82)" }}></span>
                                <span style={{ backgroundColor: "rgb(181, 227, 82)" }}></span>
                                <span style={{ backgroundColor: "rgb(181, 227, 82)" }}></span>
                                <span style={{ backgroundColor: "rgb(181, 227, 82)" }}></span>
                            </div>
                        </div>
                        <Button className='btn checkConnections bg-black' onClick={copyToClipboard}>
                            Share Result
                        </Button>

                        <div className="text-center mt-4">
                            <a href='/archive' className='btn shuffle p-3'>Start New Game</a>
                        </div>
                    </div>

                    
                </>
                 )}
                 <div className="text-center mb-2 mt-4">
            <Button
                className={`btn btn-difficulty mx-1 ${activeDifficulty === 'easy' ? 'active' : ''}`}
                onClick={() => handleButtonClick('Easy')}
            >
                Easy
            </Button>
            <Button
                className={`btn btn-difficulty mx-1 ${activeDifficulty === 'medium' ? 'active' : ''}`}
                onClick={() => handleButtonClick('Medium')}
            >
                Medium
            </Button>
            <Button
                className={`btn btn-difficulty mx-1 ${activeDifficulty === 'hard' ? 'active' : ''}`}
                onClick={() => handleButtonClick('Hard')}
            >
                Hard
            </Button>
        </div>
                {message && <Alert variant={messageType} className="text-center mt-3">{message}</Alert>}
            </div>
        </>
    );
};

export default Game;
