import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Here you would typically handle form submission, e.g., send data to an API
    // For now, we just show a success message
    setAlertType('success');
    setAlertMessage('Your message has been sent successfully!');
    setShowAlert(true);

    // Clear the form
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <Container className="contact-us">
      <Helmet>
        <title>Contact Us Connections Unlimited NYT Game | Play Latest & Classic Puzzles</title>
        <meta name="description"
          content="Connections Unlimited NYT Game Offers New and Past Puzzles. Enjoy Daily Challenges, Explore Archived Games, and improve your Word Skills Effortlessly." />
        <link rel="canonical" href="https://www.connectionunlimited.net/contact-us" />
      </Helmet>
      <Row>
        <Col md={12}>
          <h1>Contact Us</h1>
          {showAlert && (
            <Alert variant={alertType} onClose={() => setShowAlert(false)} dismissible>
              {alertMessage}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Enter your name"
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Enter your email"
              />
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder="Enter your message"
              />
            </Form.Group>

            <Button className='my-3' variant="primary" type="submit">
              Send Message
            </Button>
          </Form>
        </Col>

      </Row>
    </Container>
  );
};

export default ContactUs;
