import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import StylishSpinner from './StylishSpinner';
import Time from './Timer';
import ConfettiComponent from './ConfettiComponent';
import { Helmet } from 'react-helmet';

const Game = () => {
    const [data, setData] = useState([]); // Shuffled clues
    const [groups, setGroups] = useState([]); // Original groups
    const [loading, setLoading] = useState(true);
    const [selectedItems, setSelectedItems] = useState([]);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [gameComplete, setGameComplete] = useState(false);
    const [mistakes, setMistakes] = useState(0);
    const [shake, setShake] = useState(false);
    const [completedGroups, setCompletedGroups] = useState([]); // Store completed groups
    const groupColors = ["#ffdb58", "#50c878", "#468fea", "#b272e0"];
    const [gameName, setGameName] = useState(''); // Store gameName
    const [createdBy, setCreatedBy] = useState(''); // Store createdBy

    // Get file parameter from URL
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const file = params.get("file");

    // Fetch data for the selected file
    useEffect(() => {
        const fetchData = async () => {
            if (!file) return; // Ensure there's a file parameter

            try {
                setLoading(true);
                const response = await axios.get(`https://www.connectionunlimited.net/gamedata/groupuser.php?file=${file}`);
                const groupData = response.data.data.groups || [];

                setGameName(response.data.data.gameName || '');
                setCreatedBy(response.data.data.createdBy || '');

                setGroups(groupData); // Store original groups structure

                const shuffledData = shuffleArray(groupData.flatMap(group => group.clues));
                setData(shuffledData); // Store shuffled clues
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [file]);

    // Shuffle array function
    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    // Handle item click
    const handleItemClick = (item) => {
        setSelectedItems(prevItems => {
            const isAlreadySelected = prevItems.includes(item);
            if (isAlreadySelected) {
                return prevItems.filter(selected => selected !== item);
            } else {
                if (prevItems.length < 4) {
                    return [...prevItems, item];
                } else {
                    setMessage('You can only select up to 4 items.');
                    setMessageType('info');
                    return prevItems;
                }
            }
        });
    };

    // Handle form submission
    const handleSubmit = () => {
        if (selectedItems.length !== 4) {
            setMessage('You need to select 4 items before submitting.');
            setMessageType('info');
            return;
        }

        // Check if selected items match any group
        const matchedGroup = groups.find(group => {
            const groupSet = new Set(group.clues); // Convert group clues to a set
            const selectedSet = new Set(selectedItems); // Convert selected items to a set

            return groupSet.size === selectedSet.size &&
                [...groupSet].every(clue => selectedSet.has(clue)); // Check if sets are equal
        });

        if (matchedGroup) {
            const matchedIndex = groups.indexOf(matchedGroup); // Find the index of the matched group
            setMessage('Success! You correctly identified the group.');
            setMessageType('success');
            setGameComplete(true);

            // setCompletedGroups(prevGroups => [...prevGroups, { groupName: matchedGroup.category, members: matchedGroup.clues }]);
            setCompletedGroups(prevGroups => [...prevGroups, {
                groupName: matchedGroup.category,
                members: matchedGroup.clues,
                index: matchedIndex
            }]);

            // Remove matched group clues from the data pool
            setData(prevData => prevData.filter(item => !matchedGroup.clues.includes(item)));
            setSelectedItems([]); // Clear selected items
        } else {
            setMistakes(prevMistakes => {
                if (prevMistakes < 3) {
                    setShake(true); // Trigger shake animation
                    setTimeout(() => setShake(false), 500); // Reset shake after animation
                    setMessage(`Incorrect guess, try again. Mistakes remaining: ${3 - prevMistakes}`);
                    setMessageType('warning');
                } else {
                    setMessage('Game over! You have made 4 mistakes.');
                    setMessageType('danger');
                    setGameComplete(true);
                }
                return prevMistakes + 1;
            });
        }
    };

    const handleDeselect = () => {
        setSelectedItems([]);
    };

    // Updated getGroupClass function
    const getGroupClass = (index) => {
        const color = groupColors[index] || "light"; // Default to light if index exceeds groupColors length
        return { backgroundColor: color, padding: "10px", borderRadius: "5px" };
    };

    // Function to shuffle clues
    const handleShuffle = () => {
        setData(prevData => shuffleArray([...prevData])); // Shuffle the current data
        setMessage('The clues have been shuffled!');
        setMessageType('info');
    };

    const [showModal, setShowModal] = useState(true); // Modal visibility
    const [currentURL, setCurrentURL] = useState('');
    const [copyMessage, setCopyMessage] = useState(''); // Message for copy action

    useEffect(() => {
        // Get the current page URL and set it
        setCurrentURL(window.location.href);
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(currentURL); // Copy URL to clipboard
        setCopyMessage('Link copied to clipboard!');
        setTimeout(() => setCopyMessage(''), 2000); // Clear message after 2 seconds
    };
    const copyToClipboard = () => {
        const textToCopy = "connectionunlimited.net - 🟡🟡🟡🟡🔵🔵🔵🔵🔴🔴🔴🔴🟢🟢🟢🟢";
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                alert("Copied to Clipboard, you can now share the result in social media.");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    };

    return (
        <>
            {/* Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Created Successfully!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Share this game!</p>
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                        <svg
                            viewBox="0 0 24 24"
                            focusable="false"
                            className="chakra-icon css-i8h9c1"
                            onClick={handleCopy}
                            style={{
                                position: 'absolute',
                                left: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                                width: '20px',
                                height: '20px',
                                color: 'grey',
                            }}
                        >
                            <path
                                fill="currentColor"
                                d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
                            ></path>
                        </svg>
                        <input
                            type="text"
                            value={currentURL}
                            readOnly
                            style={{
                                flex: 1,
                                padding: '8px 8px 8px 40px', // Extra left padding to accommodate the icon
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                fontSize: '14px',
                            }}
                        />
                    </div>
                    {copyMessage && <p style={{ color: 'green', marginTop: '10px' }}>{copyMessage}</p>}

                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn checkConnections' onClick={handleCopy}>
                        Copy Link
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="game-container">
                 <Helmet>
                              <title>Own Game Connections Unlimited NYT Game | Play Latest & Classic Puzzles</title>
                              <meta name="description"
                                content="Create Connections Unlimited NYT Game Offers New and Past Puzzles. Enjoy Daily Challenges, Explore Archived Games, and improve your Word Skills Effortlessly." />
                              <link rel="canonical" href="https://www.connectionunlimited.net/own-game" />
                            </Helmet>
                <div className='row p-0'>
                    <h5 className="text-center mt-3 mb-0 fw-bold">{gameName}</h5>
                    <p className="text-center mb-0">Created by {createdBy}</p>
                    <Time />
                    {completedGroups.length > 0 && (
                        <div className="mt-4 group-success">
                            {completedGroups.map((group, index) => (
                                <div
                                    key={index}
                                    className="game-group"
                                    style={getGroupClass(group.index)}
                                >
                                    <h3 className="group-name">{group.groupName}</h3>
                                    <div className="group-members">
                                        {group.members.join(', ')}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {completedGroups.length < 4 ? (
                    <>
                {loading ? (
                    <StylishSpinner />
                ) : (
                    <div id="grid" className="">
                        {data.map((member, index) => {
                            const charCount = member.length; // Count the number of characters
                            const charClass = `letter-${charCount}`;
                            return (
                                <div
                                    key={index}
                                    onClick={() => handleItemClick(member)}
                                    className={`word ${charClass} ${selectedItems.includes(member) ? 'selected' : ''}
                                ${shake && selectedItems.includes(member) ? 'shake' : ''}`}
                                >
                                    {member}
                                </div>
                            );
                        })}
                    </div>
                )}

                <div className="mistakes" id="mistakes">
                    Mistakes remaining:
                    {Array(4) // Total 4 dots for mistakes
                        .fill()
                        .map((_, index) => (
                            <span
                                key={index}
                                className={`mx-2 mistake-dot ${index < mistakes ? 'used' : ''}`}
                            ></span>
                        ))}
                </div>
                <div className="text-center mt-4">

                    <Button onClick={() => setShowModal(true)} className="shuffle mx-1">Share</Button>
                    <Button onClick={handleShuffle} className="shuffle mx-1">Shuffle</Button>
                    <Button onClick={handleDeselect} className="deselectAll mx-1">Deselect</Button>
                    <Button onClick={handleSubmit} className="checkConnections mx-1">Submit</Button>
                </div>
                </>
                ) : (
                    <>
                     <div className="congratulations">
                        {/* <h2>Congratulations!</h2> */}
                        {/* <p>You have found all groups!</p> */}
                        <ConfettiComponent />
                        <div className="share">
                            <div className="share-row">
                                <span style={{ backgroundColor: "rgb(114, 158, 235)" }}></span>
                                <span style={{ backgroundColor: "rgb(114, 158, 235)" }}></span>
                                <span style={{ backgroundColor: "rgb(114, 158, 235)" }}></span>
                                <span style={{ backgroundColor: "rgb(114, 158, 235)" }}></span>
                            </div>
                            <div className="share-row">
                                <span style={{ backgroundColor: "rgb(251, 212, 0)" }}></span>
                                <span style={{ backgroundColor: "rgb(251, 212, 0)" }}></span>
                                <span style={{ backgroundColor: "rgb(251, 212, 0)" }}></span>
                                <span style={{ backgroundColor: "rgb(251, 212, 0)" }}></span>
                            </div>
                            <div className="share-row">
                                <span style={{ backgroundColor: "rgb(188, 112, 196)" }}></span>
                                <span style={{ backgroundColor: "rgb(188, 112, 196)" }}></span>
                                <span style={{ backgroundColor: "rgb(188, 112, 196)" }}></span>
                                <span style={{ backgroundColor: "rgb(188, 112, 196)" }}></span>
                            </div>
                            <div className="share-row">
                                <span style={{ backgroundColor: "rgb(181, 227, 82)" }}></span>
                                <span style={{ backgroundColor: "rgb(181, 227, 82)" }}></span>
                                <span style={{ backgroundColor: "rgb(181, 227, 82)" }}></span>
                                <span style={{ backgroundColor: "rgb(181, 227, 82)" }}></span>
                            </div>
                        </div>
                        <Button className='btn checkConnections bg-black' onClick={copyToClipboard}>
                            Share Result
                        </Button>

                
                    </div>

                    </>
                
                )}

                {message && <Alert variant={messageType} className="text-center mt-3">{message}</Alert>}
            </div>
        </>
    );
};

export default Game;
